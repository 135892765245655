import { ResponsiveValue } from 'styled-system'

/**
 * Use this theme when you need to use theme values in css`` blocks.
 *
 * E.g.
 *
 * css`
 *  margin-top: ${theme.space}[1]
 * `
 *
 * This is the newer theme used by all styles using the `<Box />` -component.
 */
const theme = {
  breakpoints: {
    // This exists only in types, the way "styled-system" works is that any unknown breakpoint will
    // have no media queries so basically "min-width: 0px"
    // xs: '0px'
    // TODO: Make "sm" 480px and
    xsm: '480px',
    sm: '720px',
    md: '920px',
    lg: '1280px',
    desktopMenu: '1200px', // sizes.subMenu + horizontal padding
    demoWide: '1360px',
  },

  space: [
    0,
    2,
    4,
    8,
    10, // not a power of two, but a one-off value
    12, // not a power of two, but a "half-note" (8 * 1.5)
    16,
    20, // not a power of two, but a one-off value
    24, // not a power of two, but a "half-note" (16 * 1.5)
    32,
    36,
    40, // not a power of two, but a one-off value
    42,
    48,
    64,
    92,
    120,
    148,
  ],

  fontSizes: [11, 12, 13, 14, 15, 16, 18, 20, 24, 28, 32, 36, 40, 48, 56],

  fontWeights: [300, 400, 500, 600, 700],

  lineHeights: ['110%', '120%', '130%', '140%', '150%', '170%', '20px'],

  colors: {
    primary: '#645ACB',
    secondary: '#8A8F9D',
    podcastLink: '#C8956C',
    blue: '#0960E3',
    darkBlue: '#002263',
    darkBanner: '#262244',
    purple: '#6252E2',
    darkButton: '#21263A',
    homeHeroBg: '#0F0E2F',
    homeHeroBgMobile: '#09091C',
    darkPurple: '#1A1A43',
    dataPurple: '#645ACB',
    hoverPurple: '#4A40A9',
    dataGreen: '#55AF7D',
    dataPink: '#E767C1',
    errorRed: '#EF6B6B',
    bookBlue: '#2F74DF',
    illustrationPink: '#EFA6F1',
    purple500: '#6252E2',
    purple600: '#4C40AF',
    pink: '#E767C1',
    hoverHighlight: '#E7EEF9',
    lightHighlight: 'rgb(217 217 217 / 10%)',
    transparent: 'transparent',
    white: '#fff',
    gray50: '#F4F4F4',
    gray100: '#E5E5E5',
    gray200: '#E1E5EE',
    black50: '#F2F3F4',
    black100: '#D9DCDE',
    black200: '#BFC3C8',
    black300: '#A4AAB3',
    black400: '#898F9E',
    black500: '#6E7489',
    black600: '#595F72',
    black700: '#454A5B',
    black800: '#313642',
    black900: '#1E212A',
    black: '#000',
    blue50: '#F0F4FA',
    blue100: '#E1E7F5',
    blue900: '#01122C',
    green100: '#CDEBD0',
    green600: '#218D56',
    inherit: 'inherit',
    linkUnderline: '#FFC3D2',
    linkUnderlineHover: '#EA5F94',
    blockLight: '#2A295B',
    blockDark: '#1B1A44',
    blockLightBorder: '#493C96',
    logoBlockDark: '#010105',
    logoWallLink: '#88A7EE',
    darkBlurbBg: '#242452',
    darkBlurbHighlight: '#454569',
    quoteBar: '#E1E7F5',
    blueHeading: '#1A66D8',
    darkMenuText: '#899FCF',
    darkMenuLinkText: '#EFF1F7',
    circleBackground: '#0F0F28',
    book: '#2E74DE',
  },

  fonts: {
    inherit: 'inherit',
    Monospace:
      "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;",
    FactorA:
      "'Factor A', Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    Inter:
      "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },

  letterSpacings: ['0.03em', '-0.01em', '-0.02em', '0.08em'],

  sizes: {
    auto: 'auto',
    '100vw': '100vw',
    '100%': '100%',
    // TODO: Update this once old theme / Block usage is gone
    full: 1440,
    largeBlock: 1440,
    mediumBlock: 960,
    smallBlock: 640,
    blog: 712,
    blurb: 320,
    largeBlurb: 540,
    mosaic: 580,
    mosaicText: 480,
    ctaConfirm: 460,
    // Note: consider updating the desktopMenu breakpoint if adjusting this
    subMenu: 1175,
    blogContent: 620,
  },

  borderWidths: { thin: 1 },

  radii: [4, 6, 8, 12, 16, 24, 32, 48, 9999],

  shadows: {
    none: 'none',
    tiny: '0px 3px 3px 0px rgba(0, 0, 0, 0.05)',
    soft: 'box-shadow: 0px 0px 38px 0px rgba(23, 23, 26, 0.05), 0px 0px 12px 0px rgba(0, 0, 0, 0.03);',
    small:
      'rgba(0, 0, 0, 0.05) 0px 8px 24px, rgba(23, 23, 26, 0.05) 0px 12px 24px',
    normal:
      '0px 13px 45px rgba(0, 0, 0, 0.05), 0px 17px 45px rgba(23, 23, 26, 0.05)',
    strong:
      '0px 13px 45px rgba(0, 0, 0, 0.1), 0px 17px 45px rgba(23, 23, 26, 0.12)',
  },
} as const

type Theme = typeof theme
type ValueOf<T> = T[keyof T]

export type ThemeFontSize = ResponsiveValue<Theme['fontSizes'][number]>
export type ThemeLineHeight = ResponsiveValue<Theme['lineHeights'][number]>
export type ThemeColor = ResponsiveValue<keyof Theme['colors']>
export type ThemeBorderWidth = ResponsiveValue<keyof Theme['borderWidths']>
export type ThemeFontWeight = ResponsiveValue<Theme['fontWeights'][number]>
export type ThemeLetterSpacing = ResponsiveValue<
  Theme['letterSpacings'][number]
>

type FontVariant = {
  fontFamily: ValueOf<Theme['fonts']>
  fontSize?: ThemeFontSize
  marginBottom?: Theme['space'][number]
  lineHeight?: ThemeLineHeight
  fontWeight: ThemeFontWeight
  letterSpacing?: ThemeLetterSpacing
  color?: ThemeColor
  textTransform?: 'uppercase' | 'none'
}

export const fontVariants: Record<string, FontVariant> = {
  h1: {
    fontFamily: theme.fonts.FactorA,
    // styled-system variants don't support object syntax
    fontSize: [36, 40, null, 56],
    lineHeight: '110%',
    letterSpacing: ['-0.01em', null, null, '-0.02em'],
    fontWeight: 700,
    // Fight global styles
    marginBottom: 0,
  },
  h2: {
    fontFamily: theme.fonts.FactorA,
    // styled-system variants don't support object syntax
    fontSize: [32, null, null, 40],
    lineHeight: '120%',
    fontWeight: 700,
    // Fight global styles
    marginBottom: 0,
  },
  h3: {
    fontFamily: theme.fonts.FactorA,
    fontSize: [28, null, null, 32],
    lineHeight: '120%',
    fontWeight: 500,
    // Fight global styles
    marginBottom: 0,
  },
  h4: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 24,
    lineHeight: '120%',
    fontWeight: 500,
    // Fight global styles
    marginBottom: 0,
  },
  h5: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 20,
    lineHeight: '130%',
    fontWeight: 700,
    // Fight global styles
    marginBottom: 0,
  },
  h6: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 18,
    lineHeight: '120%',
    fontWeight: 700,
    // Fight global styles
    marginBottom: 0,
  },
  badge: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '140%',
  },
  large: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 20,
    lineHeight: '150%',
    fontWeight: 400,
  },
  medium: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 18,
    lineHeight: '140%',
    fontWeight: 400,
  },
  small: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 16,
    lineHeight: '140%',
    fontWeight: 400,
  },
  buttonLabel: {
    fontFamily: theme.fonts.FactorA,
    fontWeight: 500,
  },
  textLabel: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 18,
    lineHeight: '120%',
    fontWeight: 500,
  },
  smallLabel: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 13,
    lineHeight: '140%',
    fontWeight: 500,
  },
  smallPill: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 11,
    lineHeight: '110%',
    fontWeight: 700,
    letterSpacing: '0.03em',
  },
  bookQuote: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 24,
    lineHeight: '130%',
    fontWeight: 400,
  },
  productMenuLink: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: theme.fonts.FactorA,
    lineHeight: '120%',
  },
  logoWallLink: {
    fontFamily: theme.fonts.Inter,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '120%',
    letterSpacing: '0.08em',
  },
  blogH1: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 46 as ThemeFontSize,
    lineHeight: '120%',
    fontWeight: 700,
    letterSpacing: '-0.01em',
  },
  // These are also duplicated in the .prose raw CSS
  blogH2: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 30 as ThemeFontSize,
    lineHeight: '140%',
    fontWeight: 700,
    letterSpacing: '-0.01em',
  },
  blogText: {
    fontFamily: theme.fonts.Inter,
    fontSize: 18,
    lineHeight: '180%' as ThemeLineHeight,
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  blogMetadata: {
    fontFamily: theme.fonts.Inter,
    fontSize: 20,
    lineHeight: '172%' as ThemeLineHeight,
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  blogLarge: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 20,
    lineHeight: '130%' as ThemeLineHeight,
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  blogImageAlt: {
    fontFamily: theme.fonts.Inter,
    fontSize: 16,
    lineHeight: '150%',
    fontWeight: 400,
    color: 'black500',
  },
  blueh6: {
    fontFamily: theme.fonts.FactorA,
    color: 'blueHeading',
    fontSize: 16,
    fontWeight: 700,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    letterSpacing: '1px' as any,
    textTransform: 'uppercase',
  },
  circleH1: {
    fontFamily: theme.fonts.FactorA,
    fontWeight: 300,
    // styled-system variants don't support object syntax
    fontSize: [69, null, null, 160] as ThemeFontSize,
    lineHeight: '100%' as ThemeLineHeight,
    letterSpacing: ['-1.38px', null, null, '-6.4px'] as ThemeLetterSpacing,
    // Fight global styles
    marginBottom: 0,
  },
  circleH2: {
    fontFamily: theme.fonts.FactorA,
    fontWeight: 300,
    fontSize: 32,
    lineHeight: '120%',
  },
  circleH3: {
    fontFamily: theme.fonts.FactorA,
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '120%',
  },
  circlePill: {
    fontFamily: theme.fonts.FactorA,
    fontSize: [13, null, null, 17] as ThemeFontSize,
    lineHeight: '140%',
    fontWeight: 400,
    letterSpacing: ['-0.134px', null, null, '-0.168px'] as ThemeLetterSpacing,
  },
  circleTerms: {
    fontFamily: theme.fonts.Inter,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '-0.14px' as ThemeLetterSpacing,
  },
  tag: {
    fontFamily: theme.fonts.FactorA,
    fontSize: 15,
    fontWeight: 700,
    lineHeight: '140%',
    letterSpacing: '0.45px' as ThemeLetterSpacing,
    textTransform: 'uppercase',
  },
}

/**
 * This export is used for compatibility with styled-system.
 * We don't utilize their theme functionality for most things, but rather just
 * limit the styles that our `<Box />` component can take in TypeScript.
 *
 * See stylePropTypes.ts for the types.
 *
 * Here's a few sources of information on the theme specification for the technically inclined:
 *
 * @see https://styled-system.com/theme-specification/
 * @see https://github.com/primer/components/blob/main/src/theme-preval.js
 * @see https://styled-system.com/guides/why-powers-of-two/
 * @see https://github.com/styled-system/styled-system/blob/master/packages/space/src/index.js#L4
 * @see https://www.figma.com/file/2YJF2uNmWmngamPIE9rsXI/Swarmia-Design-System?node-id=945%3A505
 * @see https://www.figma.com/file/lYkeZmzZO720ydXNVeyq8V/Swarmia-Colors?node-id=0%3A1
 * @see node_modules/@types/styled-system/index.d.ts#Theme
 */
export const styledSystemTheme = {
  // Styled system breakpoints need to be also an array
  breakpoints: Object.assign(
    Object.values(theme.breakpoints),
    theme.breakpoints,
  ),

  fonts: theme.fonts,
  colors: theme.colors,
  shadows: theme.shadows,
  sizes: theme.sizes,
  letterSpacings: theme.letterSpacings,
  borderWidths: theme.borderWidths,

  // We don't use the styled-system scale for these, but use empty values
  // to prevent styled system from overriding these with defaults.
  space: [],
} as const

export default theme
